import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import demo2 from '../views/demo2.vue'
import demo1 from '../views/demo1.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path:'/demo2',
    name:'demo2',
    component:demo2
  },
  {
    path:'/demo1',
    name:'demo1',
    component:demo1
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base:'/'
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
return originalPush.call(this, location).catch(err => err)
}

export default router
