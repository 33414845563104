<template>
    <div>
        <div>
            <el-input v-model="nameSearch" size="mini" placeholder="输入项目名称" />
            <el-input v-model="batSearch" size="mini" placeholder="输入项目编号批次" />
            <el-input v-model="timeSearch" size="mini" placeholder="输入项目公布时间" />
            <el-input v-model="classSearch" size="mini" placeholder="输入项目类别" />
            <el-input v-model="provinceSearch" size="mini" placeholder="输入项目所在省份" />
            <el-button @click="insertcomfirm">插入</el-button>
            <el-button @click="routerUser">跳转至传承人管理</el-button>

        </div>
        <div>
            <el-table :data="filteredData" style="width: 100%">
                <el-table-column label="项目序号" prop="pro_id">
                </el-table-column>
                <el-table-column label="项目名称" prop="pro_name">
                </el-table-column>
                <el-table-column label="项目编号批次" prop="pro_bat">
                </el-table-column>
                <el-table-column label="项目编号项次" prop="pro_num">
                </el-table-column>
                <el-table-column label="项目公布时间" prop="pro_time">
                </el-table-column>
                <el-table-column label="项目类别" prop="pro_class">
                </el-table-column>
                <el-table-column label="项目图片" prop="pro_pic">
                    <template slot-scope="scope">
                        <img :src="scope.row.pro_pic" alt="项目图片" style="max-width: 100%; max-height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column label="项目申报地区或单位" prop="pro_dec">
                </el-table-column>
                <el-table-column label="项目保护单位" prop="pro_prot">
                </el-table-column>
                <el-table-column label="项目所在省份" prop="pro_province">
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="confirmDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div>
            <el-dialog :visible.sync="insertDialogVisible" title="插入项目">
                <el-form :model="formInline" class="demo-form-inline">
                    <el-form-item label="项目名称">
                        <el-input v-model="formInline.pro_name" placeholder="项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编号批次">
                        <el-select v-model="formInline.pro_bat" placeholder="项目编号批次">
                            <el-option v-for="bat in bats" :key="bat" :label="bat" :value="bat"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目编号项次">
                        <el-input v-model="formInline.pro_num" placeholder="项目编号项次"></el-input>
                    </el-form-item>
                    <el-form-item label="项目公布时间">
                        <el-input v-model="formInline.pro_time" placeholder="项目公布时间"></el-input>
                    </el-form-item>
                    <el-form-item label="项目类别">
                        <el-select v-model="formInline.pro_class" placeholder="项目类别">
                            <el-option v-for="Class in classes" :key="Class" :label="Class" :value="Class"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目申报地区或单位">
                        <el-input v-model="formInline.pro_dec" placeholder="项目申报地区或单位"></el-input>
                    </el-form-item>
                    <el-form-item label="项目保护单位">
                        <el-input v-model="formInline.pro_prot" placeholder="项目保护单位"></el-input>
                    </el-form-item>
                    <el-form-item label="项目图片">
                        <el-upload class="avatar-uploader" 
                            action="http://47.107.146.203:8088/project/insertImage" :show-file-list="false"
                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="formInline.pro_pic" :src="formInline.pro_pic" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" style="line-height:178px"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="项目描述">
                        <el-input type="textarea" v-model="formInline.pro_cont" placeholder="项目描述"></el-input>
                    </el-form-item>
                    <el-form-item label="项目所在省份">
                        <el-select v-model="formInline.pro_province" placeholder="项目所在省份">
                            <el-option v-for="province in provinces" :key="province" :label="province"
                                :value="province"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="insert">插入</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="editDialogVisible" title="更新项目">
                <el-form :model="formInlinee" class="demo-form-inline">
                    <el-form-item label="项目名称">
                        <el-input v-model="formInlinee.pro_name" placeholder="项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编号批次">
                        <el-select v-model="formInlinee.pro_bat" placeholder="项目编号批次">
                            <el-option v-for="bat in bats" :key="bat" :label="bat" :value="bat"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目编号项次">
                        <el-input v-model="formInlinee.pro_num" placeholder="项目编号项次"></el-input>
                    </el-form-item>
                    <el-form-item label="项目公布时间">
                        <el-input v-model="formInlinee.pro_time" placeholder="项目公布时间"></el-input>
                    </el-form-item>
                    <el-form-item label="项目类别">
                        <el-select v-model="formInlinee.pro_class" placeholder="项目类别">
                            <el-option v-for="Class in classes" :key="Class" :label="Class" :value="Class"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目申报地区或单位">
                        <el-input v-model="formInlinee.pro_dec" placeholder="项目申报地区或单位"></el-input>
                    </el-form-item>
                    <el-form-item label="项目保护单位">
                        <el-input v-model="formInlinee.pro_prot" placeholder="项目保护单位"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="项目图片地址">
                        <el-input v-model="formInlinee.pro_pic" placeholder="项目图片地址"></el-input>
                    </el-form-item> -->
                    <el-form-item label="项目图片">
                        <el-upload class="avatar-uploader" 
                            action="http://47.107.146.203:8088/project/insertImage" :show-file-list="false"
                            :on-success="updateAvatarSuccess" :before-upload="beforeupdateUpload">
                            <img v-if="formInlinee.pro_pic" :src="formInlinee.pro_pic" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" style="line-height:178px"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="项目描述">
                        <el-input type="textarea" v-model="formInlinee.pro_cont" placeholder="项目描述"></el-input>
                    </el-form-item>
                    <el-form-item label="项目所在省份">
                        <el-select v-model="formInlinee.pro_province" placeholder="项目所在省份">
                            <el-option v-for="province in provinces" :key="province" :label="province"
                                :value="province"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="update">更新</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            tableData: [],
            nameSearch: '',
            batSearch: '',
            timeSearch: '',
            classSearch: '',
            provinceSearch: '',
            insertDialogVisible: false,
            editDialogVisible: false,
            URL: 'http://47.107.146.203:8080/',
            formInline: {
                pro_id: null,
                pro_name: "",
                pro_bat: 'I',
                pro_num: null,
                pro_time: null,
                pro_class: "传统技艺",
                pro_dec: "",
                pro_prot: "",
                pro_pic: "",
                pro_cont: "",
                pro_province: '北京市'
            },
            formInlinee: {
                pro_id: null,
                pro_name: "",
                pro_bat: '',
                pro_num: null,
                pro_time: null,
                pro_class: "",
                pro_dec: "",
                pro_prot: "",
                pro_pic: "",
                pro_cont: "",
                pro_province: ''
            },
            provinces: ['北京市', '天津市', '河北省', '山西省', '内蒙古自治区', '辽宁省', '吉林省', '黑龙江省', '上海市', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省', '广西壮族自治区', '海南省', '重庆市', '四川省', '贵州省', '云南省', '西藏自治区', '陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区', '台湾省', '香港特别行政区', '澳门特别行政区'],
            bats: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'],
            classes: ['民间文学', '传统音乐', '传统舞蹈', '传统戏剧', '曲艺', '传统体育、游艺与杂技', '传统美术', '传统技艺', '传统医药', '民俗'],
        }
    },
    computed: {
        filteredData() {
            return this.tableData.filter(data =>
                (!this.nameSearch || data.pro_name.toLowerCase().includes(this.nameSearch.toLowerCase())) &&
                (!this.batSearch || data.pro_bat === this.batSearch) &&
                (!this.timeSearch || data.pro_time.toString().includes(this.timeSearch)) &&
                (!this.classSearch || data.pro_class.toString().includes(this.classSearch)) &&
                (!this.provinceSearch || data.pro_province.toString().includes(this.provinceSearch))
            );
        },
    },
    created() {
        this.getdata()
    },
    methods: {
        handleEdit(index, row) {
            this.editDialogVisible = true
            this.formInlinee = row

        },
        getdata() {
            this.axios.post(`http://47.107.146.203:8088/project/all`).then((resp) => {
                console.log(resp.data)
                this.tableData = resp.data
            })
        },
        confirmDelete(index, row) {
            this.$confirm(`确定删除项目 ${row.pro_name} 吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.axios.post(`http://47.107.146.203:8088/project/delete/${row.pro_id}`).then((resp) => {
                        console.log(resp)
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        })
                        this.getdata()
                    })
                })
                .catch(() => {

                });
        },
        insertcomfirm() {
            this.insertDialogVisible = true;
        },
        // 图片上传成功
        handleAvatarSuccess(res, file, fileList) {
            this.formInline.pro_pic = res
            console.log(res,'res');
        },
        beforeAvatarUpload() {
            return true
        },
        updateAvatarSuccess(res, file, fileList) {
            this.formInlinee.pro_pic = res
            console.log(res,'res');
        },
        beforeupdateUpload() {
            return true
        },
        insert() {
            this.axios.post('http://47.107.146.203:8088/project/insert', this.formInline).then((resp) => {
                console.log(resp.data)
                this.$message({
                    message: '插入成功！',
                    type: 'success'
                })
                this.formInline = {}
                this.getdata()
            })
            this.insertDialogVisible = false;
            
        },
        update() {
            this.axios.post(`http://47.107.146.203:8088/project/updateImage/${this.formInlinee.pro_id}`)
            this.axios.post(`http://47.107.146.203:8088/project/update`,this.formInlinee).then((resp) => {
                console.log(resp.data)
                this.$message({
                    message: '更新成功！',
                    type: 'success'
                })
                this.getdata()
            })
            this.editDialogVisible = false;
        },
        routerUser() {
            this.$router.push({ path: '/demo1' })
        }
    }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>

