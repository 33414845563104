<template>
    <div>
        <div>
            <el-input v-model="nameSearch" size="mini" placeholder="输入项目名称" />
            <el-input v-model="batSearch" size="mini" placeholder="输入项目编号批次" />
            <el-input v-model="unameSearch" size="mini" placeholder="输入传承人姓名" />
            <el-input v-model="classSearch" size="mini" placeholder="输入项目类别" />
            <el-input v-model="proidSearch" size="mini" placeholder="输入项目编号" />
            <el-button @click="insertcomfirm">插入</el-button>
            <el-button @click="routerPro">跳转至项目管理</el-button>
        </div>
        <div>
            <el-table :data="filteredData" style="width: 100%">
                <el-table-column label="编号" prop="u_no">
                </el-table-column>
                <el-table-column label="姓名" prop="u_name">
                </el-table-column>
                <el-table-column label="性别" prop="u_gender">
                </el-table-column>
                <el-table-column label="出生日期" prop="u_birth">
                </el-table-column>
                <el-table-column label="民族" prop="u_gro">
                </el-table-column>
                <el-table-column label="项目类别" prop="u_proclass">
                </el-table-column>
                <el-table-column label="项目批次" prop="u_probat">
                </el-table-column>
                <el-table-column label="项目项次" prop="u_pronum">
                </el-table-column>
                <el-table-column label="项目名称" prop="u_proname">
                </el-table-column>
                <el-table-column label="项目申报地区和单位" prop="u_prodec">
                </el-table-column>
                <el-table-column label="项目编号" prop="u_proid">
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="confirmDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div>
            <el-dialog :visible.sync="insertDialogVisible" title="插入项目">
                <el-form :model="formInline" class="demo-form-inline">
                    <el-form-item label="姓名">
                        <el-input v-model="formInline.u_name" placeholder="请输入传承人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-select v-model="formInline.u_gender" placeholder="请输入传承人性别">
                            <el-option v-for="gender in genders" :key="gender" :label="gender" :value="gender"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出生年月">
                        <el-date-picker v-model="formInline.u_birth" type="month" placeholder="选择传承人出生年月"
                            format="yyyy-MM"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="民族">
                        <el-select v-model="formInline.u_gro" placeholder="请输入传承人民族">
                            <el-option v-for="gro in groes" :key="gro" :label="gro" :value="gro"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目类别">
                        <el-select v-model="formInline.u_proclass" placeholder="请输入项目类别">
                            <el-option v-for="Class in classes" :key="Class" :label="Class" :value="Class"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目批次">
                        <el-select v-model="formInline.u_probat" placeholder="请输入项目批次">
                            <el-option v-for="bat in bats" :key="bat" :label="bat" :value="bat"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目项次">
                        <el-input v-model="formInline.u_pronum" placeholder="请输入项目保护项次"></el-input>
                    </el-form-item>
                    <el-form-item label="项目名称">
                        <el-input v-model="formInline.u_proname" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目申报地区和单位">
                        <el-input v-model="formInline.u_prodec" placeholder="请输入项目申报地区和单位"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编号">
                        <el-input v-model="formInline.u_proid" placeholder="请输入项目对应编号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="insert">插入</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="editDialogVisible" title="更新项目">
                <el-form :model="formInlinee" class="demo-form-inline">
                    <el-form-item label="姓名">
                        <el-input v-model="formInlinee.u_name" placeholder="请输入传承人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-select v-model="formInlinee.u_gender" placeholder="请输入传承人性别">
                            <el-option v-for="gender in genders" :key="gender" :label="gender" :value="gender"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出生年月">
                        <el-date-picker v-model="formInlinee.u_birth" type="month" placeholder="选择传承人出生年月"
                            format="yyyy-MM"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="民族">
                        <el-select v-model="formInlinee.u_gro" placeholder="请输入传承人民族">
                            <el-option v-for="gro in groes" :key="gro" :label="gro" :value="gro"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目类别">
                        <el-select v-model="formInlinee.u_proclass" placeholder="请输入项目类别">
                            <el-option v-for="Class in classes" :key="Class" :label="Class" :value="Class"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目批次">
                        <el-select v-model="formInlinee.u_probat" placeholder="请输入项目批次">
                            <el-option v-for="bat in bats" :key="bat" :label="bat" :value="bat"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目项次">
                        <el-input v-model="formInlinee.u_pronum" placeholder="请输入项目保护项次"></el-input>
                    </el-form-item>
                    <el-form-item label="项目名称">
                        <el-input v-model="formInlinee.u_proname" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="项目申报地区和单位">
                        <el-input v-model="formInlinee.u_prodec" placeholder="请输入项目申报地区和单位"></el-input>
                    </el-form-item>
                    <el-form-item label="项目编号">
                        <el-input v-model="formInlinee.u_proid" placeholder="请输入项目对应编号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="update">更新</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            tableData: [],
            nameSearch: '',
            batSearch: '',
            unameSearch: '',
            classSearch: '',
            proidSearch: '',
            insertDialogVisible: false,
            editDialogVisible: false,
            formInline: {
                u_no: null,
                u_name: '',
                u_gender: '男',
                u_birth: '',
                u_gro: '汉',
                u_proclass: "传统技艺",
                u_probat: "",
                u_pronum: null,
                u_proname: "",
                u_prodec: "",
                u_proid: null
            },
            formInlinee: {
                u_no: null,
                u_name: "",
                u_gender: '男',
                u_birth: '',
                u_gro: '汉',
                u_proclass: "传统技艺",
                u_probat: "",
                u_pronum: null,
                u_proname: "",
                u_prodec: "",
                u_proid: null
            },
            bats: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'],
            classes: ['民间文学', '传统音乐', '传统舞蹈', '传统戏剧', '曲艺', '传统体育、游艺与杂技', '传统美术', '传统技艺', '传统医药', '民俗'],
            genders: ['男', '女'],
            groes: [
                '汉',
                '蒙古',
                '回',
                '藏',
                '维吾尔',
                '苗',
                '彝',
                '壮',
                '布依',
                '朝鲜',
                '满',
                '侗',
                '瑶',
                '白',
                '土家',
                '哈尼',
                '哈萨克',
                '傣',
                '黎',
                '傈僳',
                '佤',
                '畲',
                '高山',
                '拉祜',
                '水',
                '东乡',
                '纳西',
                '景颇',
                '柯尔克孜',
                '土',
                '达斡尔',
                '仫佬',
                '羌',
                '布朗',
                '撒拉',
                '毛南',
                '仡佬',
                '锡伯',
                '阿昌',
                '普米',
                '塔吉克',
                '怒',
                '乌孜别克',
                '俄罗斯',
                '鄂温克',
                '德昂',
                '保安',
                '裕固',
                '京',
                '塔塔尔',
                '独龙',
                '鄂伦春',
                '赫哲',
                '门巴',
                '珞巴',
                '基诺'
            ],
        }
    },
    computed: {
        filteredData() {
            return this.tableData.filter(data =>
                (!this.nameSearch || data.u_proname.toLowerCase().includes(this.nameSearch.toLowerCase())) &&
                (!this.batSearch || data.u_probat === this.batSearch) &&
                (!this.unameSearch || data.u_name.toString().includes(this.unameSearch)) &&
                (!this.classSearch || data.u_proclass.toString().includes(this.classSearch)) &&
                (!this.proidSearch || data.u_proid.toString() === this.proidSearch)
            );
        },
    },
    created() {
        this.getdata()
    },
    methods: {
        handleEdit(index, row) {
            this.editDialogVisible = true
            this.formInlinee = row
        },
        getdata() {
            this.axios.post(`http://47.107.146.203:8088/user/all`).then((resp) => {
                console.log(resp.data)
                this.tableData = resp.data
            })
        },
        confirmDelete(index, row) {
            this.$confirm(`确定删除传承人 ${row.u_name} 吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.axios.post(`http://47.107.146.203:8088/user/delete/${row.u_no}`).then((resp) => {
                        console.log(resp)
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        })
                        this.getdata()
                    })
                })
                .catch(() => {

                });
        },
        insertcomfirm() {
            this.insertDialogVisible = true;
        },
        insert() {
            if(this.formInline.u_birth == ''){
                this.formInline.u_birth = '不详'
            }
            this.axios.post(`http://47.107.146.203:8088/user/insert`, this.formInline).then((resp) => {
                console.log(resp.data)
                this.$message({
                    message: '插入成功！',
                    type: 'success'
                })
                this.getdata()
            })
            this.insertDialogVisible = false;
        },
        update() {
            if(this.formInline.u_birth == ''){
                this.formInline.u_birth = '不详'
            }
            this.axios.post(`http://47.107.146.203:8088/user/update`, this.formInlinee).then((resp) => {
                console.log(resp.data)
                this.$message({
                    message: '更新成功！',
                    type: 'success'
                })
                this.getdata()
            })
            this.editDialogVisible = false;
        },
        routerPro() {
            this.$router.push({ path: '/demo2' })
        }

    }
}
</script>